import { CurrencyPipe, DecimalPipe, PercentPipe, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  HowdenAlertsModule,
  HowdenCoreModule,
  HowdenErrorPagesModule,
  HowdenPageTitleModule,
  HowdenUserService,
  IHowdenEnvironment,
  IPackageJsonWrapper,
  NotificationsService,
  getPaginatorIntl
} from '@howdeniberia/core-front';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage } from 'ngx-webstorage';
import { ApiConfigurations } from 'src/environments/api-configurations';
import { environment } from 'src/environments/environment';
import packageJson from '../../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationsModule } from './features/applications/applications.module';
import { CompaniesModule } from './features/companies/companies.module';
import { ExternalAppsModule } from './features/external-apps/external-apps.module';
import { UsersModule } from './features/users/users.module';

registerLocaleData(localeEs, 'es');

function getLocaleId(userSrv: HowdenUserService) {
  return userSrv?.userData?.selectedLanguage ?? 'es';
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HowdenPageTitleModule,
    HowdenErrorPagesModule,
    HowdenAlertsModule,
    HowdenCoreModule.forRoot(environment as IHowdenEnvironment, packageJson as IPackageJsonWrapper, ApiConfigurations),
    AppRoutingModule,
    ApplicationsModule,
    ExternalAppsModule,
    CompaniesModule,
    UsersModule
  ],
  providers: [
    provideNgxWebstorage(
      withNgxWebstorageConfig({ caseSensitive: true }),
      withLocalStorage(),
      withSessionStorage()
    ),
    NotificationsService,
    DecimalPipe,
    CurrencyPipe,
    PercentPipe,
    { provide: LOCALE_ID, useFactory: getLocaleId, deps: [HowdenUserService] },
    // { provide: MAT_DATE_LOCALE, useFactory: getLocaleId, deps: [HowdenUserService] },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, autoFocus: true, restoreFocus: true } },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
